<template>
  <v-container v-if="token !== null || token == null">
    <app-loader v-if="loading"></app-loader>
    <v-form ref="formStep1">
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete
            v-model="tipoDocumentoSeleccionado"
            label="Tipo documento*"
            outlined
            :items="catalogoTipoDocumentos"
            item-text="nombre"
            item-value="id"
            :error-messages="documentoErrors"
            :disabled="dataPersona !== null"
            return-object
            class="rounded-lg"
            required
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="numeroDocumentoIngresado"
            label="Número de documento*"
            outlined
            :disabled="deshabilitarNumeroDocumento || dataPersona !== null"
            v-facade="documento_mask"
            class="rounded-lg"
            color="#697182"
            required
            :maxlength="maxCaracteresNumeroDocumento"
            counter
            :error-messages="NumeroDocumentoErrors"
            @blur="$v.numeroDocumentoIngresado.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <!-- <v-btn rounded class="mr-5 text-capitalize" outlined
            style="color: #1C1E4D; background-color: white; border-color: #1C1E4D;" @click="verfiDui"
            :disabled="tipoDocumentoSeleccionado != 1">
            Verificar
          </v-btn> -->
          <v-select
            v-model="estadoFamiliar"
            label="Estado familiar*"
            outlined
            :items="catalogoEstadoCivil"
            item-value="id"
            item-text="nombre"
            :error-messages="estadoFamiliarError"
            @blur="$v.estadoFamiliar.$touch()"
            class="rounded-lg"
            color="#697182"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="primerNombre"
            label="Primer nombre*"
            outlined
            :disabled="dataPersona !== null"
            class="rounded-lg"
            color="#697182"
            required
            maxlength="50"
            counter
            :rules="[rules.required]"
            @input="notEmojis($event, 'primerNombre', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'primerNombre', false)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="segundoNombre"
            label="Segundo nombre"
            outlined
            :disabled="dataPersona !== null"
            class="rounded-lg"
            color="#697182"
            maxlength="50"
            counter
            :error-messages="SegundoNombreError"
            @blur="$v.segundoNombre.$touch()"
            @input="notEmojis($event, 'segundoNombre', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'segundoNombre', false)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="tercerNombre"
            label="Tercer nombre"
            outlined
            class="rounded-lg"
            color="#697182"
            maxlength="50"
            counter
            :error-messages="tercerNombreError"
            @blur="$v.tercerNombre.$touch()"
            @input="notEmojis($event, 'tercerNombre', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'tercerNombre', false)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="primerApellido"
            label="Primer apellido*"
            outlined
            :disabled="dataPersona !== null"
            class="rounded-lg"
            color="#697182"
            required
            maxlength="50"
            counter
            :rules="[rules.required]"
            @input="notEmojis($event, 'primerApellido', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'primerApellido', false)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="segundoApellido"
            label="Segundo apellido"
            outlined
            :disabled="dataPersona !== null"
            class="rounded-lg"
            color="#697182"
            maxlength="50"
            counter
            :error-messages="SegundoApellidoError"
            @blur="$v.segundoApellido.$touch()"
            @input="notEmojis($event, 'segundoApellido', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'segundoApellido', false)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="tercerApellido"
            label="Apellido de casada"
            outlined
            class="rounded-lg"
            color="#697182"
            maxlength="50"
            counter
            @input="notEmojis($event, 'tercerApellido', true)"
            v-on:keypress="soloTexto($event)"
            v-on:paste="notEmojis($event, 'tercerApellido', false)"
            :disabled="sexoSeleccionado !== 1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete
            v-model="nacionalidadSeleccionada"
            label="Nacionalidad*"
            outlined
            :items="catalogoNacionalidad"
            item-text="gentilicio"
            item-value="id"
            :error-messages="nacionalidadErrors"
            class="rounded-lg"
            :disabled="catalogoNacionalidad.length === 0"
            @change="$v.nacionalidadSeleccionada.$touch()"
            required
          ></v-autocomplete>
        </v-col>

        <!-- Campos nuevos -->
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete
            v-model="deptoSeleccionado"
            label="Departamentos*"
            outlined
            :items="CatalogoDepto"
            item-text="nombre"
            item-value="id"
            :error-messages="deptosErrors"
            class="rounded-lg"
            required
            @change="$v.deptoSeleccionado.$touch()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete
            v-model="distritoSeleccionado"
            label="Distritos*"
            outlined
            :items="CatalogoDistritos"
            item-text="nombre"
            item-value="id"
            :error-messages="distritosErrors"
            class="rounded-lg"
            required
            :disabled="!deptoSeleccionado"
          ></v-autocomplete>
        </v-col>
        <!-- /Campos nuevos -->

        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete
            v-model="sexoSeleccionado"
            label="Sexo*"
            outlined
            :items="catalogoSexo"
            item-text="nombre"
            item-value="id"
            :error-messages="sexoErrors"
            class="rounded-lg"
            :disabled="dataPersona !== null"
            required
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                color="color"
                :error-messages="FechaNacimientoErrors"
                v-model="fechaFormatted"
                label="Fecha de nacimiento*"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="dataPersona !== null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaNacimiento"
              @input="menu = false"
              locale="es"
              :max="fechaActual"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-text-field
            v-model="numeroTelefono"
            label="Teléfono/Celular*"
            outlined
            v-mask="'####-####'"
            class="rounded-lg"
            color="blueConaipd"
            :rules="[rules.telefono_requerido, rules.formato_telefono]"
            required
            ><!-- :error-messages="CelularErrors" --></v-text-field
          >
        </v-col>
        <v-col cols="12" xs="12" sm="12" v-if="$vuetify.breakpoint.smAndDown">
          <v-autocomplete
            v-model="tipoDeSangreSeleccionado"
            label="Tipo de sangre"
            outlined
            :items="catalogoTipoDeSangre"
            item-text="nombre"
            clearable
            item-value="id"
            class="rounded-lg"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-file-input
            accept="image/png, image/jpeg, image/jpg"
            prepend-icon="mdi-camera"
            label="Adjuntar foto*"
            outlined
            @click:clear="eliminarFotoPerfil()"
            hint="Formatos permitidos PNG, JPEG, JPG y peso máx. 2MB"
            persistent-hint
            v-model="fotoPerfil"
            truncate-length="13"
            :error-messages="fotoPerfilError"
            @change="$v.fotoPerfil.$touch()"
          >
            <template #append-outer v-if="isRegister">
              <v-btn icon @click="descargarDoc">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-file-input>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          lg="4"
          md="4"
          xl="4"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-autocomplete
            v-model="tipoDeSangreSeleccionado"
            label="Tipo de sangre"
            outlined
            :items="catalogoTipoDeSangre"
            item-text="nombre"
            clearable
            item-value="id"
            class="rounded-lg"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" lg="4" md="4" xl="4">
          <v-autocomplete v-model="tipoDeSangreSeleccionado" label="Tipo de sangre*" outlined
                          :items="catalogoTipoDeSangre" :error-messages="tipoSangreError" item-text="nombre"
                          item-value="id"
                          class="rounded-lg"></v-autocomplete>
        </v-col> -->
      </v-row>
      <v-row v-if="$route.name === 'registro'">
        <v-col cols="12" v-if="urlFotoPerfil !== null">
          <center>
            <img
              v-if="urlFotoPerfil"
              :src="urlFotoPerfil"
              style="border-radius: 20px; width: 175px; height: 200px"
            />
          </center>
        </v-col>
        <v-col cols="12" v-if="fotoAnterior !== null && urlFotoPerfil === null">
          <center>
            <img
              :src="fotoAnterior"
              style="border-radius: 20px; width: 175px; height: 200px"
            />
          </center>
        </v-col>
      </v-row>
      <v-row>
        <v-col :class="errorCountOfe ? 'border--alert' : ''">
          <p class="text-h6 font-weight-bold">Sobre mí*</p>
          <vue-editor
            v-model="descripcionSobreMi"
            label="Sobre mí*"
            :editorToolbar="customToolbar"
            @text-change="validEditor"
            @selection-change="validEditor"
          ></vue-editor>
          <div class="d-flex flex-row align-center justify-start">
            <p class="error--text pt-4 pl-2">
              {{ ckeditorErrorMessage[0] ?? "" }}
            </p>
            <v-spacer></v-spacer>
            <div
              style="font-weight: 400 !important; color: #707070"
              class="text-start text-subtitle-2"
              :class="errorCountOfe ? 'error--text' : ''"
            >
              {{ wordCountOfe }} / {{ maxCaracteres }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import jwtDecode from "jwt-decode";
import { required, requiredIf, helpers } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AppLoader from "@/components/AppLoader.vue";
import { VueEditor } from "vue2-editor";

const stripHtml = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

const noEmojisOrSpecialChars = (value) => {
  if (!value) return true; // Permitir valores vacíos
  const plainText = stripHtml(value); // Elimina etiquetas HTML si existen
  const noEmojiPattern = /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/; // Sin emojis
  const noSpecialCharPattern = /^[a-zA-ZáÁéÉíÍóÓúÚüÜñÑ\s]+$/; // Solo caracteres válidos
  return noEmojiPattern.test(plainText) && noSpecialCharPattern.test(plainText);
};

const validtypeDocBoolean = (value, vm, typesExtention) => {
  if (value) {
    return typesExtention.some((ext) => ext === value.type);
  } else {
    return false;
  }
};
export default {
  name: "Paso1",
  props: {
    ArrayPersona: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueEditor,
    AppLoader,
  },
  data() {
    return {
      CatalogoDepto: [],
      CatalogoDistritos: [],
      CatalogoPais: [],
      deptoSeleccionado: null,
      distritoSeleccionado: null,
      fechaActual: "",
      tipoDocumentoSeleccionado: null,
      numeroDocumentoIngresado: null,
      primerNombre: null,
      segundoNombre: null,
      tercerNombre: null,
      primerApellido: null,
      segundoApellido: null,
      tercerApellido: null,
      nacionalidadSeleccionada: null,
      loading: false,
      sexoSeleccionado: null,
      fechaNacimiento: null,
      numeroTelefono: null,
      fotoPerfil: null,
      ckeditorErrorMessage: [],
      editor: ClassicEditor,
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
      ],
      maxCaracteres: 500,
      errorCountOfe: false,
      wordCountOfe: 0,
      isPasting: false,
      tipoDeSangreSeleccionado: null,
      descripcionSobreMi: null,
      estadoFamiliar: null,
      urlFotoPerfil: null,
      fotoAnterior: null,
      isRegister: false,
      textAlertDoc: "",
      deshabilitarNumeroDocumento: true,
      deshabilitarBtnVerificar: true,
      maxCaracteresNumeroDocumento: 0,
      documento_mask: null,
      menu: false,
      catalogoEstadoCivil: [],
      validacionEditor: true,
      catalogoTipoDocumentos: [],
      catalogoNacionalidad: [],
      catalogoSexo: [],
      catalogoTipoDeSangre: [],
      rules: {
        required: (value) => !!value || "Campo requerido",
        telefono_requerido: (value) => !!value || "Telefono requerido",
        formato_telefono: (value) => {
          const pattern = /^[2,6-7]{1}[0-9]{3}[-]{1}[0-9]{4}$/;
          return (
            (value && pattern.test(value)) ||
            "El teléfono debe tener 8 números y comenzar con 2, 7 o 6"
          );
        },
        contraseña: (value) => {
          const pattern =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;
          return (
            (value && pattern.test(value)) ||
            "La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico"
          );
        },
        minCaracteresDocumento: (value) => {
          return false;
        },
      },
      v_mask_array: {
        celular: "####-####",
        dui: "########-#",
        nit: "####-######-###-#",
      },
      regex_telefono: /^[2,6-7]{1}[0-9]{3}[-]{1}[0-9]{4}$/,
      regex_password:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,

      dataPersona: null,
      personaData: null,
    };
  },
  validations: {
    tipoDocumentoSeleccionado: { required },
    // numeroDocumentoIngresado: {
    //   required,
    //   formatoDocumento: function (value) {
    //     if (this.tipoDocumentoSeleccionado) {
    //       if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[0])
    //           || this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[1])) {
    //         this.textAlertDoc = "Formato de DUI inválido"
    //         return this.isDui(value)
    //       } else if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[2])) {
    //         this.textAlertDoc = "Formato de NIT inválido"
    //         return this.isNit(value)
    //       } else if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[3])) {
    //         this.textAlertDoc = "Formato de NUP inválido"
    //         return value.length === 12
    //       } else if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[4])) {
    //         this.textAlertDoc = "Formato de licencia inválido"
    //         return this.isDui(value)
    //       } else if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[5])) {
    //         this.textAlertDoc = "Formato de carnet residente inválido"
    //         return value.length === 7
    //       } else if (this.validSelectedDoc(this.tipoDocumentoSeleccionado.nombre) === this.normalizeAndSimplify(this.typeDocNumber[6])) {
    //         this.textAlertDoc = "Formato de ISSS inválido"
    //         return value.length === 9
    //       } else {
    //         return false
    //       }
    //     } else {
    //       return false
    //     }
    //   }
    // },
    primerNombre: { required },
    segundoNombre: { noEmojisOrSpecialChars },
    tercerNombre: {
      noEmojisOrSpecialChars(value) {
        if (!value) return true; // Permite valores vacíos
        const plainText = stripHtml(value); // Asegúrate de tener esta función implementada
        const noEmojiPattern =
          /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
        const noSpecialCharPattern = /^[a-zA-ZáÁéÉíÍóÓúÚüÜñÑ\s]+$/;
        return (
          noEmojiPattern.test(plainText) && noSpecialCharPattern.test(plainText)
        );
      },
    },
    segundoApellido: { noEmojisOrSpecialChars },
    primerApellido: { required },
    nacionalidadSeleccionada: { required },
    deptoSeleccionado: { required },
    distritoSeleccionado: {
      required: requiredIf(function () {
        return this.deptoSeleccionado !== null;
      }),
    },
    sexoSeleccionado: { required },
    fechaNacimiento: { required },
    estadoFamiliar: { required },
    fotoPerfil: {
      required,
      validtypeDocBoolean: function (value, vm) {
        if (value) {
          const ext = ["image/png", "image/jpeg", "image/jpg"];
          return validtypeDocBoolean(value, vm, ext);
        } else {
          return true;
        }
      },
      validSizeDoc: function (value) {
        if (value && value.size > 2097152) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  methods: {
    getFechaActual() {
      // Obtener la fecha actual en zona horaria local
      let today = new Date();
      let localISODate =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);
      this.fechaActual = localISODate;
    },
    async validar_paso() {
      // console.log("CLICK");
      // Marca todos los campos como tocados
      this.$v.$touch();
      // Si el campo de descripción está vacío, muestra el error
      if (this.ckeditorErrorMessage.length > 0) return false;
      if (this.wordCountOfe === 0) {
        this.errorCountOfe = true;
        this.ckeditorErrorMessage = ["Campo requerido"];
        return false; // Detiene el proceso si el campo está vacío
      }
      // Verifica si la validación es correcta (sin errores) y si el formulario es válido
      if (this.$v.$invalid) {
        // // Recorre cada campo en $v y muestra el estado de su validez
        // for (const key in this.$v) {
        //   if (this.$v[key].$invalid) {
        //     // Imprime qué campo está inválido y por qué
        //     console.log(`Campo inválido: ${key}`);
        //     console.log(`Errores en ${key}:`, this.$v[key].$error);
        //   }
        // }
        // // Aquí también puedes devolver false para evitar continuar si el formulario es inválido
        return false;
      }
      // Verifica que el formulario específico también sea válido (si lo estás usando)
      if (this.$refs.formStep1 && !this.$refs.formStep1.validate()) {
        // console.log("Formulario Step 1 no válido");
        return false; // Si el formulario de referencia no es válido, no procede
      }
      // Si no hay errores, devuelve true para proceder
      // console.log("Formulario válido, pasando al siguiente paso");
      return true;
    },
    normalizeAndSimplify(text) {
      if (text) {
        return text
          .normalize("NFD")
          .replace(/[\u0300-\u036f.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
          .toLowerCase();
      }
    },
    validSelectedDoc(nombre) {
      return this.normalizeAndSimplify(nombre);
    },
    validEditor(event) {
      const noEmojiPattern =
        /^[^\uD83C-\uDBFF\uDC00-\uDFFF\u200D\u2600-\u27BF]*$/;
      const noSpecialCharPattern = /^[a-zA-Z0-9áÁéÉíÍóÓúÚñÑ.,;:\-\/\s]+$/;
      const noUrlPattern = /https?:\/\/[^\s]+|www\.[^\s]+/i;

      if (event && event.hasOwnProperty("ops")) {
        const descripcionSinHTML = this.descripcionSobreMi
          .replace(/<[^>]*>/g, "")
          .trim();
        this.wordCountOfe = descripcionSinHTML.length;

        if (this.wordCountOfe > 0) {
          if (this.wordCountOfe > this.maxCaracteres) {
            this.errorCountOfe = true;
            this.ckeditorErrorMessage = [
              "Ha superado el máximo de caracteres permitidos",
            ];
          } else if (!noEmojiPattern.test(descripcionSinHTML)) {
            this.errorCountOfe = true;
            this.ckeditorErrorMessage = ["No se permiten emojis en el campo"];
          } else if (!noSpecialCharPattern.test(descripcionSinHTML)) {
            this.errorCountOfe = true;
            this.ckeditorErrorMessage = [
              "No se permiten caracteres especiales",
            ];
          } else if (noUrlPattern.test(descripcionSinHTML)) {
            this.errorCountOfe = true;
            this.ckeditorErrorMessage = ["No se permiten URLs en el campo"];
          } else {
            this.errorCountOfe = false;
            this.validacionEditor = false;
            this.ckeditorErrorMessage = [];
          }
        } else {
          this.errorCountOfe = true;
          this.ckeditorErrorMessage = ["Campo requerido"];
        }
      }
    },
    stripHTMLTags(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    },
    soloTexto(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑ/\s]+$/.test(char)) return true;
      else e.preventDefault();
    },
    notEmojis(e, campo, isInput) {
      const valAcentos = /^[ÁÉÍÓÚáéíóúñÑ\s]+$/;
      if (/[^ -~]/.test(e) && valAcentos.test(e)) {
        if (valAcentos.test(e)) {
          if (this.validateNotEmojis(e) && /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(e))
            return true;
          else false;
        } else {
          this.$nextTick(() => {
            this[campo] = e.replace(/[^\x00-\x7F]/g, "");
          });
          return true;
        }
      } else if (isInput) {
        if (this.validateNotEmojis(e) && /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(e))
          return true;
        else false;
      } else {
        const value = e.clipboardData.getData("text");
        if (
          this.validateNotEmojis(value) &&
          /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]+$/.test(value)
        )
          return true;
        else e.preventDefault();
      }
    },
    async obtener_data() {
      //console.log("ENTRE");
      if (!this.$v.$invalid) {
        //console.log("OK");
        let datos_paso_uno = {
          tipoDocumentoSeleccionado: this.tipoDocumentoSeleccionado.id,
          numeroDocumentoIngresado:
            this.numeroDocumentoIngresado.numeroDocument,
          estadoFamiliar: this.estadoFamiliar,
          primerNombre: this.primerNombre,
          segundoNombre: this.segundoNombre,
          tercerNombre: this.tercerNombre || null,
          primerApellido: this.primerApellido,
          segundoApellido: this.segundoApellido,
          tercerApellido: this.tercerApellido,
          nacionalidadSeleccionada: this.nacionalidadSeleccionada,
          sexoSeleccionado: this.sexoSeleccionado,
          fechaNacimiento: this.fechaNacimiento,
          numeroTelefono: this.numeroTelefono,
          fotoPerfil: this.fotoPerfil,
          tipoDeSangreSeleccionado: this.tipoDeSangreSeleccionado,
          descripcionSobreMi: this.descripcionSobreMi,
          idPersona: this.dataPersona.id,
          emailPersonal: this.dataPersona.email_personal,
          // deptoSeleccionado: this.deptoSeleccionado,
          distritoSeleccionado: this.distritoSeleccionado,
        };
        return datos_paso_uno;
      }
    },

    eliminarFotoPerfil() {
      (this.fotoPerfil = null), (this.urlFotoPerfil = null);
    },
    previsualizarFotoPerfil() {
      const blob = new Blob([this.fotoPerfil]);
      this.urlFotoPerfil = URL.createObjectURL(blob);
    },
    async verfiDui() {
      //this.showLoader();
      if (!this.isDui(this.numeroDocumentoIngresado)) {
        // this.hideLoader();
        return this.temporalAlert({
          show: true,
          message: "El número de DUI no es válido",
          type: "error",
        });
      }
      try {
        const response = await this.services.formulario.getPersonaRnpn({
          numero_documento: this.numeroDocumento,
        });
        //console.log(response);
        //this.passDataPersona(response.data);
      } catch (e) {
        this.temporalAlert({
          show: true,
          message:
            e.response.data.err.description ||
            "Error al obtener los datos de DUI",
          type: "warning",
        });
      } finally {
        //this.hideLoader();
      }
    },
    async getTiposDocumentos() {
      const response = await this.services.documento.getTiposDocumentos();
      this.catalogoTipoDocumentos = response.data;
    },

    cargarDistritos() {
      this.distritoSeleccionado = null;
      if (this.deptoSeleccionado) {
        this.getDistritos(this.deptoSeleccionado);
      } else {
        this.CatalogoDistritos = [];
      }
    },
    async getDepartamento() {
      const response = await this.services.documento.getDepartamento({});
      this.CatalogoDepto = response.data;
    },
    async getPais() {
      const response = await this.services.documento.getPais({});
      this.catalogoNacionalidad = response.data;
    },
    async getDistritos(id_departamento) {
      const response = await this.services.documento.getDistritos(
        id_departamento
      );
      this.CatalogoDistritos = response.data;
    },
    async getSexo() {
      const response = await this.services.datosPersona.getSexo();
      this.catalogoSexo = response.data;
    },
    async getTipoSangre() {
      const response = await this.services.datosPersona.getTipoSangre();
      this.catalogoTipoDeSangre = response.data;
    },
    async getEstadoCivil() {
      const response = await this.services.datosPersona.getEstadoCivil();
      this.catalogoEstadoCivil = response.data;
    },
    async getPersona() {
      const response = await this.services.datosPersona.getDatosPersona(
        this.dataPersona.id
      );
      this.personaData = response?.data;
    },
    async setData(data = {}) {
      const typeDoc = this.catalogoTipoDocumentos.filter(
        (doc) => doc.id === data.documento_identidad[0].id_tipo_documento
      );
      this.tipoDocumentoSeleccionado = typeDoc[0];
      this.numeroDocumentoIngresado = data?.documento_identidad[0]?.numero;
      this.estadoFamiliar = data.id_estado_civil;
      this.primerNombre =
        data?.primer_nombre && data?.primer_nombre !== "null"
          ? data?.primer_nombre.charAt(0).toUpperCase() +
            data?.primer_nombre.slice(1)
          : null;
      this.segundoNombre =
        data?.segundo_nombre && data?.segundo_nombre !== "null"
          ? data?.segundo_nombre.charAt(0).toUpperCase() +
            data?.segundo_nombre.slice(1)
          : null;
      this.tercerNombre =
        data?.tercer_nombre && data?.tercer_nombre !== "null"
          ? data?.tercer_nombre.charAt(0).toUpperCase() +
            data?.tercer_nombre.slice(1)
          : null;
      this.primerApellido =
        data?.primer_apellido && data?.primer_apellido !== "null"
          ? data?.primer_apellido.charAt(0).toUpperCase() +
            data?.primer_apellido.slice(1)
          : null;
      this.segundoApellido =
        data.segundo_apellido && data.segundo_apellido !== "null"
          ? data?.segundo_apellido.charAt(0).toUpperCase() +
            data?.segundo_apellido.slice(1)
          : null;
      this.tercerApellido =
        data.apellido_casada && data.apellido_casada !== "null"
          ? data?.apellido_casada.charAt(0).toUpperCase() +
            data?.apellido_casada.slice(1)
          : null;
      this.nacionalidadSeleccionada = data.id_pais;
      this.deptoSeleccionado = data.residencia?.id_departamento || null;
      setTimeout(() => {
        this.distritoSeleccionado = data.residencia?.id || null;
        if (this.$forceUpdate) {
          this.$forceUpdate();
        }
      }, 100);
      this.sexoSeleccionado = data.id_sexo;
      this.fechaNacimiento = data.fecha_nacimiento;
      this.numeroTelefono = data.telefono;
      this.tipoDeSangreSeleccionado = data.id_tipo_sangre
        ? data.id_tipo_sangre
        : null;
      this.descripcionSobreMi = data?.descripcion;

      if (data.ruta_foto) {
        await this.showDocument(this.dataPersona.id);
        this.isRegister = true;
        const mimeType = this.fotoAnterior.split(";")[0].split(":")[1];
        this.fotoPerfil = this.base64ToFile(
          this.fotoAnterior,
          "Documento.",
          mimeType
        );
      } else {
        this.fotoPerfil = null;
        this.fotoAnterior = require(`@/assets/img/default_user.png`);
      }
    },
    async showDocument(idPersona) {
      const params = {
        tipo_consulta: "F",
        tipo_documento: 1,
        id_persona: idPersona,
      };
      try {
        let response = await this.services.datosPersona.getDocumentoPersona(
          params
        );
        if (response.status == 200) {
          this.fotoAnterior = response?.data.archivo;
          this.urlFotoPerfil = null;
        }
      } catch (e) {}
    },
    base64ToFile(base64String, fileName, mimeType) {
      // Obtener la cadena Base64 sin el encabezado
      const base64WithoutHeader = base64String.split(",")[1];
      const ext = mimeType.split("/")[1];
      // Convertir la cadena Base64 a un ArrayBuffer
      const binaryData = atob(base64WithoutHeader);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const view = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        view[i] = binaryData.charCodeAt(i);
      }
      // Crear un Blob a partir del ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: mimeType || "application/octet-stream",
      });
      // Crear un objeto File a partir del Blob
      return new File([blob], `${fileName}${ext}` || "file", {
        type: mimeType || "application/octet-stream",
      });
    },
    descargarDoc() {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.fotoPerfil);
      link.download = this.fotoPerfil.name;
      link.click();
    },
  },
  computed: {
    ...mapState(["userInfo", "token"]),
    documentoErrors() {
      const errors = [];
      if (!this.$v.tipoDocumentoSeleccionado?.$dirty) return errors;
      !this.$v.tipoDocumentoSeleccionado?.required &&
        errors.push("Este campo es requerido");
      return errors;
    },
    nacionalidadErrors() {
      const errors = [];
      if (!this.$v.nacionalidadSeleccionada?.$dirty) return errors;
      if (!this.$v.nacionalidadSeleccionada?.required) {
        errors.push("Este campo es requerido");
      }
      return errors;
    },
    deptosErrors() {
      const errors = [];
      if (!this.$v.deptoSeleccionado?.$dirty) return errors;
      if (!this.$v.deptoSeleccionado?.required) {
        errors.push("Este campo es requerido");
      }
      return errors;
    },
    distritosErrors() {
      const errors = [];
      if (!this.$v.distritoSeleccionado?.$dirty) return errors;
      if (!this.$v.distritoSeleccionado?.required) {
        errors.push("Este campo es requerido");
      }
      return errors;
    },
    sexoErrors() {
      const errors = [];
      if (!this.$v.sexoSeleccionado?.$dirty) return errors;
      if (!this.$v.sexoSeleccionado?.required) {
        errors.push("Este campo es requerido");
      }
      return errors;
    },
    fechaFormatted: {
      get() {
        if (this.fechaNacimiento)
          return this.moment(this.fechaNacimiento).format("DD-MM-YYYY");
      },
      set() {
        return;
      },
    },
    SegundoNombreError() {
      const errors = [];
      if (!this.$v.segundoNombre.$dirty) return errors;
      if (!this.$v.segundoNombre.noEmojisOrSpecialChars) {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    tercerNombreError() {
      const errors = [];
      if (
        this.$v.tercerNombre &&
        this.tercerNombre !== "" &&
        !this.$v.tercerNombre.noEmojisOrSpecialChars
      ) {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    SegundoApellidoError() {
      const errors = [];
      if (!this.$v.segundoApellido.$dirty) return errors;
      if (!this.$v.segundoApellido.noEmojisOrSpecialChars) {
        errors.push("Caracteres inválidos");
      }
      return errors;
    },
    FechaNacimientoErrors() {
      const errors = [];
      const fechaNac = this.moment(this.fechaNacimiento).format("YYYY-MM-DD");
      const fecAc = this.moment(this.fechaActual);
      if (fechaNac > fecAc) {
        errors.push("Fecha de nacimiento no debe ser mayor a la fecha actual");
      }
      if (!this.$v.fechaNacimiento?.$dirty) return errors;
      if (!this.$v.fechaNacimiento?.required) {
        errors.push("Este campo es requerido");
      }
      return errors;
    },
    NumeroDocumentoErrors() {
      let errors = [];
      if (!this.$v.numeroDocumentoIngresado?.$dirty) return errors;
      !this.$v.numeroDocumentoIngresado?.required &&
        errors.push("Documento requerido");
      !this.$v.numeroDocumentoIngresado?.formatoDocumento &&
        errors.push(this.textAlertDoc);
      return errors;
    },
    estadoFamiliarError() {
      const errors = [];
      if (!this.$v.estadoFamiliar?.$dirty) return errors;
      if (!this.$v.estadoFamiliar?.required)
        errors.push("Este campo es requerido");
      return errors;
    },
    fotoPerfilError() {
      const errors = [];
      if (!this.$v.fotoPerfil?.$dirty) return errors;
      !this.$v.fotoPerfil?.required &&
        errors.push("Por favor debe de seleccionar una foto");
      !this.$v.fotoPerfil?.validtypeDocBoolean &&
        errors.push("Tipo de archivo inválido");
      !this.$v.fotoPerfil?.validSizeDoc && errors.push("Tamaño máximo 2MB");
      return errors;
    },
    // descripcionError() {
    //   const errors = []
    //   if (!this.$v.descripcionSobreMi?.$dirty) return errors
    //   if (!this.$v.descripcionSobreMi?.required)
    //     errors.push("Este campo es requerido")
    //   return errors
    // }
    /* CelularErrors() {
      let errors = [];
      if (this.inputs.telefono != null) {
        let error_regex = this.regex_telefono.test(this.inputs.telefono)
        if (!error_regex) {
          errors.push("El número de teléfono debe iniciar en 2, 6 o 7, tener ocho dígitos y un guión");
        }
        else {
          errors = []
        }
      }
      if (!this.$v.inputs.telefono?.$dirty) return errors;
      if (!this.$v.inputs.telefono?.required) {
        errors.push("El número de teléfono debe iniciar en 2, 6 o 7, tener ocho dígitos");
      }
      return errors;
    }, */
  },
  mounted() {
    this.getFechaActual();
  },
  watch: {
    deptoSeleccionado(newVal) {
      if (newVal) {
        this.getDistritos(newVal);
      } else {
        this.CatalogoDistritos = []; // Limpiar distritos si no hay departamento
      }
      this.distritoSeleccionado = null; // Resetear selección de distrito
    },
    ArrayPersona: {
      handler(e) {
        //console.log("regreso",e);
      },
    },
    numeroTelefono(val) {
      if (val) {
        let cleaned = ("" + val).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{4})(\d{4})$/);
        if (match) {
          this.numeroTelefono = match[1] + "-" + match[2];
        }
      }
    },
    tipoDocumentoSeleccionado: {
      handler(e) {
        if (e != null) {
          if (
            this.validSelectedDoc(e.nombre) ===
              this.normalizeAndSimplify(this.typeDocNumber[0]) ||
            this.validSelectedDoc(e.nombre) ===
              this.normalizeAndSimplify(this.typeDocNumber[1])
          ) {
            this.maxCaracteresNumeroDocumento = 10;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          } else if (
            this.validSelectedDoc(e.nombre) ===
            this.normalizeAndSimplify(this.typeDocNumber[2])
          ) {
            this.maxCaracteresNumeroDocumento = 17;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          } else if (
            this.validSelectedDoc(e.nombre) ===
            this.normalizeAndSimplify(this.typeDocNumber[3])
          ) {
            this.maxCaracteresNumeroDocumento = 12;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          } else if (
            this.validSelectedDoc(e.nombre) ===
            this.normalizeAndSimplify(this.typeDocNumber[4])
          ) {
            this.maxCaracteresNumeroDocumento = 10;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          } else if (
            this.validSelectedDoc(e.nombre) ===
            this.normalizeAndSimplify(this.typeDocNumber[5])
          ) {
            this.maxCaracteresNumeroDocumento = 7;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          } else if (
            this.validSelectedDoc(e.nombre) ===
            this.normalizeAndSimplify(this.typeDocNumber[6])
          ) {
            this.maxCaracteresNumeroDocumento = 9;
            this.documento_mask = e.mascara;
            this.deshabilitarNumeroDocumento = false;
            // this.numeroDocumentoIngresado = null
          }
        }
      },
    },
    fotoPerfil: {
      handler(e) {
        if (e != null) {
          this.previsualizarFotoPerfil();
        }
      },
    },
  },
  async created() {
    this.loading = true;
    try {
      await this.getTiposDocumentos();
      await this.getSexo();
      await this.getTipoSangre();
      await this.getEstadoCivil();
      await this.getDepartamento();
      await this.getPais();

      if (this.token !== null) {
        const userDecode = jwtDecode(
          localStorage.getItem("token") || this.userInfo.token
        );
        this.$emit("token-hijo", userDecode);
        this.dataPersona = userDecode.persona;
        this.numeroDocumentoIngresado = this.userInfo.numero_documento;
        if (this.dataPersona?.id) {
          const response = await this.services.datosPersona.getDatosPersona(
            this.dataPersona.id
          );
          this.personaData = response?.data;
          if (response.status === 200) {
            await this.setData(this.personaData);
            // let foto = this.personaData.ruta_foto.split("/")
            //   this.numeroDocumentoIngresado = this.personaData?.documento_identidad[0]?.numero
            //   this.fotoPerfil = this.personaData.ruta_foto
            //   this.estadoFamiliar = this.personaData.id_estado_civil
            //
            //   // TODO: Corregir si la validadacion de los nombres esta incorrecta
            //
            //   this.segundoNombre = this.personaData.segundo_nombre != 'null'
            //       ? this.personaData.segundo_nombre?.charAt(0).toUpperCase() + this.personaData.segundo_nombre?.slice(1)
            //       : ""
            //
            //   this.segundoApellido = this.personaData.segundo_apellido != 'null'
            //       ? this.personaData.segundo_apellido?.charAt(0).toUpperCase() + this.personaData.segundo_apellido?.slice(1)
            //       : ""
            //   this.tercerNombre = this.personaData.tercer_nombre != 'null'
            //       ? this.personaData.tercer_nombre?.charAt(0).toUpperCase() + this.personaData.tercer_nombre?.slice(1)
            //       : ""
            //   this.tercerApellido = this.personaData.apellido_casada != 'null'
            //       ? this.personaData.apellido_casada?.charAt(0).toUpperCase() + this.personaData.apellido_casada?.slice(1)
            //       : ""
            //
            //   this.tipoDeSangreSeleccionado = this.personaData.id_tipo_sangre
            //   this.descripcionSobreMi = this.personaData.descripcion !== null ? this.personaData.descripcion : ''
            // }
            // if (this.personaData.ruta_foto) {
            //   await this.showDocument(this.dataPersona.id);
            // } else {
            //   this.fotoAnterior = require(`@/assets/img/default_user.png`);
            // }
          }
        }
      }
    } catch (error) {
      // console.log(error)
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.border--alert {
  ::v-deep .quillWrapper {
    border: 1px solid red !important;
  }
}
</style>
